import React from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./app.css";
import styles from "./app.module.css";

import { HomePage } from "./pages/HomePage";
import { TeamPage } from "./pages/TeamPage";
import { ContactPage } from "./pages/ContactPage";
import { DonateFoodPage } from "./pages/DonateFoodPage";
import { DonateMoneyPage } from "./pages/DonateMoneyPage";
import { FaqPage } from "./pages/FaqPage";
import { AchievementsPage } from "./pages/AchievementsPage";
import { HelpRequestPage } from "./pages/HelpRequestPage";

import { OrganisationPage } from "./pages/OrganisationPage";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import { PawsLanguage, PawsWebLocation, DEFAULT_ENGLISH } from "./values";
import { getBrowserLanguages, useCurrentLanguage } from "./components/langSwitcher/LangSwitcher";
import ErrorPage from "./pages/ErrorPage";
import { VolunteersPage } from "./pages/VolunteersPage";

// Component to handle language detection and redirection
const LanguageRedirect = () => {
  const navigate = useNavigate();
  const browserLanguages = getBrowserLanguages();

  React.useEffect(() => {
    const guessFn = () => {
      if (browserLanguages.includes("uk") || browserLanguages.includes("ru")) return PawsLanguage.Ukr;
      if (browserLanguages.includes("sv")) return PawsLanguage.Swe;
      return DEFAULT_ENGLISH;
    };

    navigate(`/${guessFn()}`, { replace: true });
  }, [navigate, browserLanguages]);

  return null;
};

// Component to handle language headers
const LanguageManager = () => {
  const currentLang = useCurrentLanguage();

  React.useEffect(() => {
    // Map our language codes to standard language tags
    const langMap = {
      [PawsLanguage.Ukr]: "uk-UA",
      [PawsLanguage.Eng]: "en-US",
      [PawsLanguage.Swe]: "sv-SE",
    };

    // Set the HTML lang attribute
    document.documentElement.lang = langMap[currentLang];

    // Set content-language meta tag
    let metaLang = document.querySelector('meta[http-equiv="content-language"]');
    if (!metaLang) {
      metaLang = document.createElement("meta");
      metaLang.setAttribute("http-equiv", "content-language");
      document.head.appendChild(metaLang);
    }
    metaLang.setAttribute("content", langMap[currentLang]);
  }, [currentLang]);

  return null;
};

// Wrapper component that includes both the page content and language management
const PageWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <LanguageManager />
      {children}
    </>
  );
};

const PawsApp = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Container as="main" className={classNames(styles.pawsMain)} fluid>
          <Routes>
            {/* Redirect root to detected language */}
            <Route path={PawsWebLocation.Root} element={<LanguageRedirect />} />
            <Route path={PawsWebLocation.RootAlt} element={<LanguageRedirect />} />

            {/* Language-specific routes */}
            <Route
              path="/:lang"
              element={
                <PageWrapper>
                  <HomePage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.Team}
              element={
                <PageWrapper>
                  <TeamPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.Volunteers}
              element={
                <PageWrapper>
                  <VolunteersPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.Faq}
              element={
                <PageWrapper>
                  <FaqPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.Contact}
              element={
                <PageWrapper>
                  <ContactPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.RequestHelp}
              element={
                <PageWrapper>
                  <HelpRequestPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.DonateMoney}
              element={
                <PageWrapper>
                  <DonateMoneyPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.DonateSupplies}
              element={
                <PageWrapper>
                  <DonateFoodPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.Achievements}
              element={
                <PageWrapper>
                  <AchievementsPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />
            <Route
              path={PawsWebLocation.Organisation}
              element={
                <PageWrapper>
                  <OrganisationPage />
                </PageWrapper>
              }
              errorElement={<ErrorPage />}
            />

            {/* Catch all other routes and redirect to detected language */}
            <Route path="*" element={<LanguageRedirect />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default PawsApp;
