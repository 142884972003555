import { Card, Col, Row } from "react-bootstrap";
import Tanya from "../images/teamPhoto/Tanya.png";
import TanyaK from "../images/teamPhoto/TanyaK.png";
import { PawsLangStrings, useTranslate } from "./langSwitcher/LangSwitcher";
import { PawsLanguage, pawsMemberFormUrl, pawsVolunteerFormUrl, PawsWebLocation, styrelsenValdFor } from "../values";
import { PawsLink } from "./PawsLink";

function MailTo(props: { email: string }) {
  return <a href={"mailto:" + props.email}>{props.email}</a>;
}

const strings: PawsLangStrings = {
  [PawsLanguage.Ukr]: {
    "team.title": "Правління Paws of Peace",
    Contact: "Зв'язатися",
    "Tanya Lytovchenko": "Тетяна Литовченко",
    Chairman: "Голова правління",
    "Tanya Kozoriz": "Тетяна Козоріз",
    "Vice-chairman": "Заступник голови правління",
    "Larisa Ostapenko": "Лариса Остапенко",
    Cashier: "Касир",
    "Dmytro Lytovchenko": "Дмитро Литовченко",
    "Board member": "Член правління",
    ["Rikku Bölske"]: "Рікку Бельске",
    ["Charlotte Lindström"]: "Шарлотта Ліндстрьом",
    ["Ralf Spehling"]: "Ральф Шпехлінг",
    ["Olena Goloborodko"]: "Олена Голобородько",
    Volunteers: "Волонтери і учасники",
    "Support us through becoming a member": "Підтримайте нас ставши учасником",
    "text.board": (
      <>
        Правління було обране на {styrelsenValdFor} рік і може бути переобране або змінене на початку{" "}
        {styrelsenValdFor + 1} року. Всі учасники (які платять членський внесок) можуть приєднатися до щорічного
        засідання, голосувати, а також бути обраними в правління. Ви можете зв'язатися з правлінням, надіславши листа на{" "}
        <MailTo email="board@pawsofpeace.se" />.
      </>
    ),
    "volunteers.title": "Також наші волонтери і учасники!",
  },
  [PawsLanguage.Swe]: {
    "team.title": "Styrelsen för Paws of Peace",
    Contact: "Kontakta oss",
    "Tanya Lytovchenko": "Tanya Lytovchenko",
    Chairman: "Ordförande",
    "Tanya Kozoriz": "Tanya Kozoriz",
    "Vice-chairman": "Vice ordförande",
    "Larisa Ostapenko": "Larisa Ostapenko",
    Cashier: "Kassör",
    "Dmytro Lytovchenko": "Dmytro Lytovchenko",
    "Board member": "Styrelsemedlem",
    "Support us through becoming a member": "Stöd oss genom att bli medlem",
    "text.board": (
      <>
        Styrelsen väljs för {styrelsenValdFor} och kan omväljas eller ändras tidigt {styrelsenValdFor + 1}. Alla
        betalande medlemmar kan delta i årsmötet, rösta och kan också väljas som styrelsemedlemmar. Du kan kontakta
        styrelsen genom att skicka ett e-postmeddelande till <MailTo email="board@pawsofpeace.se" />.
      </>
    ),
    "Contact us": "Kontakta oss",
    Volunteers: "Volontärer och medlemmar",
    "volunteers.title": "Också våra volontärer och stödmedlemmar!",
  },
  [PawsLanguage.Eng]: {
    "team.title": "Paws of Peace Board",
    "Larisa Ostapenko": "Larisa Ostapenko",
    "text.board": (
      <>
        The current board is elected for {styrelsenValdFor} and can be re-elected or changed early in{" "}
        {styrelsenValdFor + 1}. All paying members can join the annual meeting, cast their votes and also can be elected
        as board members. You can contact the board by sending an email to <MailTo email="board@pawsofpeace.se" />.
      </>
    ),
    "text.volunteer":
      "Volunteer work is unpaid work at the Paws of Peace location, usually involves " +
      "helping moving and packing the donations into boxes. We need volunteers available on Sundays " +
      "from 14 to 18. Want to join the volunteer team?",
    Volunteers: "Volunteers and members",
    "volunteers.title": "Also our volunteers and supporting members!",
  },
};

type BoardMember = {
  image?: string;
  name: string;
  position: string;
  contact?: string;
};

export const TeamPageBody = () => {
  const { translate: t, translateJsx } = useTranslate(strings);

  const board: BoardMember[] = [
    {
      image: Tanya,
      name: t("Tanya Lytovchenko"),
      position: t("Chairman"),
      contact: "https://www.linkedin.com/in/tanyalytovchenko/",
    },
    {
      image: TanyaK,
      name: t("Tanya Kozoriz"),
      position: t("Vice-chairman"),
      contact: "https://www.linkedin.com/in/tetiana-kozoriz-support/",
    },
    {
      image: undefined,
      name: t("Larisa Ostapenko"),
      position: t("Cashier"),
      contact: undefined,
    },
  ];
  const board2: BoardMember[] = [
    {
      image: undefined,
      name: t("Dmytro Lytovchenko"),
      position: t("Board member"),
      contact: undefined,
    },
    {
      image: undefined,
      name: t("Rikku Bölske"),
      position: t("Board member"),
      contact: undefined,
    },
    {
      image: undefined,
      name: t("Charlotte Lindström"),
      position: t("Board member"),
      contact: undefined,
    },
    {
      image: undefined,
      name: t("Ralf Spehling"),
      position: t("Board member"),
      contact: undefined,
    },
    {
      image: undefined,
      name: t("Olena Goloborodko"),
      position: t("Board member"),
      contact: undefined,
    },
  ];

  const boardMemberCard = (member: any) => {
    return (
      <Col key={member.name}>
        <Card className="h-100">
          {member.image && (
            <Card.Img
              variant="top"
              src={member.image}
              alt={member.name}
              style={{ maxHeight: "12em", objectFit: "contain" }}
            />
          )}
          <Card.Body>
            <Card.Title>{member.name}</Card.Title>
            <Card.Text>{member.position}</Card.Text>
            {/* {member.contact && (
                  <PawsLink to={member.contact} className="btn btn-link">
                    {t("Contact")}
                  </PawsLink>
                )} */}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <h3>{t("team.title")}</h3>

      <p>{translateJsx("text.board")}</p>

      <Row>{board.map(boardMemberCard)}</Row>

      <Row className="mt-2">{board2.map(boardMemberCard)}</Row>

      <h3>{t("volunteers.title")}</h3>
      <p>
        <PawsLink to={PawsWebLocation.Volunteers}>{t("Volunteers")}</PawsLink>
      </p>
    </>
  );
};
