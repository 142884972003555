import map from "../../images/map.png";
import styles from "./map.module.css";

/**
 * <iframe width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=17.922048991056197%2C59.43327024788131%2C17.953312819333785%2C59.44235882072667&amp;layer=mapnik&amp;marker=59.43781483948189%2C17.937680905194952" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=59.43781&amp;mlon=17.93768#map=16/59.43781/17.93768&amp;layers=N">Посмотреть более крупную карту</a></small>
 *
 */
export const Map = () => (
  <>
    <iframe
      src="https://www.openstreetmap.org/export/embed.html?bbox=17.922048991056197%2C59.43327024788131%2C17.953312819333785%2C59.44235882072667&amp;layer=mapnik&amp;marker=59.43781483948189%2C17.937680905194952"
      className={styles.mapBox}
    ></iframe>
    <br />
    <small>
      <a href="https://www.openstreetmap.org/?mlat=59.43781&amp;mlon=17.93768#map=16/59.43781/17.93768&amp;layers=N">
        View Larger Map
      </a>
    </small>
  </>
);

export const MapImage = () => {
  return <img src={map} alt="Paws of Peace location" width="100%" />;
};
