import { FC } from "react";
import styles from "./Header.module.css";
import logo from "../../images/logo.png";
import { LangSwitcher, PawsLangStrings } from "../langSwitcher/LangSwitcher";
import { MenuBar, MenuItemDefinition } from "./Menu";
import { Col, Row } from "react-bootstrap";
import { PawsLanguage, PawsWebLocation } from "../../values";
import { PawsLink } from "../PawsLink";

const strings: PawsLangStrings = {
  [PawsLanguage.Ukr]: {
    Home: "Головна",
    "Need help?": "Потребуєте допомоги?",
    "Donate Money": "Пожертва грошима",
    "Donate Supplies": "Пожертва речами",
    FAQs: "Питання",
    Team: "Наша команда",
    Achievements: "Наші досягнення",
    Contact: "Контакти",
    Organisation: "Наша організація",
    Volunteers: "Волонтери і учасники",
  },
  [PawsLanguage.Swe]: {
    Home: "Hem",
    "Need help?": "", // not visible in English version
    "Donate Money": "Skänk pengar",
    "Donate Supplies": "Skänk saker/foder",
    FAQs: "", // not visible in English version
    Team: "Vårt team",
    Achievements: "Våra prestationer",
    Contact: "Kontakta oss",
    Organisation: "Vår organisation",
    Volunteers: "Volontärer och medlemmar",
  },
  [PawsLanguage.Eng]: {
    "Need help?": "@", // not visible in English version
    FAQs: "@", // not visible in English version
    Volunteers: "Volunteers and Members",
  },
};

// const isDev = process.env.REACT_APP_DEV === "1";

const menuItems: MenuItemDefinition[] = [
  {
    to: PawsWebLocation.Root,
    highlightId: "home",
    text: "Home",
    nested: [
      { to: PawsWebLocation.RequestHelp, highlightId: "needHelp", text: "Need help?", lang: [PawsLanguage.Ukr] },
      { to: PawsWebLocation.Faq, highlightId: "faq", text: "FAQs", lang: [PawsLanguage.Ukr] },
    ],
  },
  {
    to: PawsWebLocation.DonateMoney,
    highlightId: "donateMoney",
    text: "Donate Money",
    nested: [{ to: PawsWebLocation.DonateSupplies, highlightId: "donateFood", text: "Donate Supplies" }],
  },
  {
    to: PawsWebLocation.Organisation,
    highlightId: "organisation",
    text: "Organisation",
    nested: [
      { to: PawsWebLocation.Team, highlightId: "team", text: "Team" },
      { to: PawsWebLocation.Achievements, highlightId: "achievements", text: "Achievements" },
    ],
  },
  {
    to: PawsWebLocation.Contact,
    highlightId: "contact",
    text: "Contact",
    nested: [{ to: PawsWebLocation.Volunteers, highlightId: "volunteers", text: "Volunteers" }],
  },
];

const Logo = () => {
  return (
    <PawsLink to={PawsWebLocation.Root}>
      <img src={logo} className={styles.logo} alt="Paws of Peace" />
    </PawsLink>
  );
};

interface HeaderProps {
  currentPageId: string;
}

export const Header: FC<HeaderProps> = ({ currentPageId }) => {
  return (
    <header className="d-flex flex-wrap justify-content-center py-2 mb-4 border-bottom">
      <Row className="w-100">
        <Col sm={1} lg={1} className={styles.logoContainer}>
          <Logo />
        </Col>

        <Col sm={9} lg={9}>
          <MenuBar strings={strings} currentPageId={currentPageId} items={menuItems} />
        </Col>

        <Col sm={1} lg={1}>
          <LangSwitcher />
        </Col>
      </Row>
    </header>
  );
};
