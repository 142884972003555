import { Header } from "../components/headerAndFooter/Header";
import { Footer } from "../components/headerAndFooter/Footer";
import BMClogo from "../images/donate/BMClogo.png";
import PPlogo from "../images/donate/PPlogo.png";
import { PawsLangStrings, useTranslate } from "../components/langSwitcher/LangSwitcher";
import { Paws100PercentDisclaimer } from "../components/prefabs";
import { Col, Row } from "react-bootstrap";
import { PawsLanguage, orgNr, pawsBankgiro, pawsBuymeacoffee, pawsPaypal, pawsSwish, swedbankOrgName } from "../values";
import moment from "moment";
import QRCode from "react-qr-code";

const strings: PawsLangStrings = {
  [PawsLanguage.Ukr]: {
    "Thank you!": "Дякуємо!",
    "Donate by Swish": `Пожертвувати через Swish (Швеція). Отримувач: ${swedbankOrgName}`,
    "Donate by Bankgiro":
      "Пожертвувати через Bankgiro (Швеція). Будь ласка, скануйте цей QR-код за допомогою вашого банківського додатку або введіть номер банкгіро напряму. Змініть суму, як вважаєте за краще.",
    "Donate by PayPal": "Пожертвувати через PayPal",
    "For international supporters.": "Для підтримки нашої справи з усіх країн світу.",
    "Donate in our ByMeACoffee fundraiser": "Пожертвувати через ByMeACoffee",
  },
  [PawsLanguage.Swe]: {
    "Thank you!": "Tack!",
    "Donate by Swish": `Donera via Swish. Mottagaren: ${swedbankOrgName}`,
    "Donate by Bankgiro":
      "Donera via Bankgiro. Vänligen skanna QR-koden med din bankapp eller skriv in bankgiro-numret direkt. Ändra beloppet som du anser lämpligt.",
    "Donate by PayPal": "Donera via PayPal",
    "For international supporters.": "För att stödja vår sak från alla länder i världen.",
    "Donate in our ByMeACoffee fundraiser": "Donera via ByMeACoffee",
  },
  [PawsLanguage.Eng]: {
    "Donate by Swish": `Donate via Swish. Recipient: ${swedbankOrgName}`,
    "Donate by Bankgiro":
      "Donate by Bankgiro (Sweden). Please scan this with your bank application or type bankgiro number directly. Change the amount as you see fit.",
  },
};

const DonateMoneyPage = () => {
  const { translate } = useTranslate(strings);
  const bankgiroQRValue = JSON.stringify({
    uqr: 1,
    tp: 1,
    nme: swedbankOrgName,
    cid: orgNr,
    iref: "1", // invoice id
    idt: moment().format("YYYYMMDD"),
    ddt: moment().add(7, "days").format("YYYYMMDD"),
    due: "150",
    pt: "BG",
    acc: pawsBankgiro,
  });
  const swishQRValue = "https://app.swish.nu/1/p/sw/?sw=1230162529&msg=&edit=msg&src=qr";

  return (
    <>
      <Header currentPageId="donateMoney" />
      <Row>
        <Col className="text-center">
          <QRCode value={swishQRValue} size={192} bgColor="#ffffff" fgColor="#000000" level="L" />
          <p className="mt-4">{translate("Donate by Swish")}</p>
          <p>
            <small className="badge text-bg-info">Swish</small> <strong>{pawsSwish}</strong>
          </p>
        </Col>

        <Col className="text-center">
          <QRCode value={bankgiroQRValue} size={192} bgColor="#ffffff" fgColor="#000000" level="L" />
          <p className="mt-4">{translate("Donate by Bankgiro")}</p>
          <p>
            <small className="badge text-bg-info">Bankgiro</small> <strong>{pawsBankgiro}</strong>
          </p>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <img src={PPlogo} alt="Paypal" width="25%" />
          <p>
            {translate("Donate by PayPal")}
            <br />
            {translate("For international supporters.")} {translate("Thank you!")}
          </p>
          <a href={pawsPaypal} className="btn btn-success">
            {"PayPal"}
          </a>
        </Col>

        <Col className="text-center">
          <img src={BMClogo} alt={"ByMeACoffee"} width="25%" />
          <p>
            {translate("Donate in our ByMeACoffee fundraiser")}
            <br />
            {translate("For international supporters.")} {translate("Thank you!")}
          </p>

          <a href={pawsBuymeacoffee} className="btn btn-success">
            {"ByMeACoffee"}
          </a>
        </Col>
      </Row>

      <Paws100PercentDisclaimer />
      <Footer />
    </>
  );
};

export { DonateMoneyPage };
