import { PawsLangStrings, useTranslate } from "./langSwitcher/LangSwitcher";
import { useLocation } from "react-router-dom";
import {
  pawsAddress,
  pawsFacebook,
  pawsInstagram,
  pawsRequestHelpAgainFormUrl,
  pawsRequestHelpFormUrl,
} from "../values";
import Facebook from "../images/contact/fb.png";
import styles from "../app.module.css";
import Instagram from "../images/contact/insta1.png";
import classNames from "classnames";
import { PawsLanguage, PawsWebLocation } from "../values";
import { PawsLink } from "./PawsLink";

const strings: PawsLangStrings = {
  [PawsLanguage.Ukr]: {
    "paws.address": `Ми працюємо у м. Стокгольм, Швеція. Точна адреса: ${pawsAddress}`,
    "paws.travelSuggestions":
      "Ми знаходимося між двома станціями приміського електропотягу: Sollentuna та Häggvik, є автобуси, або 15 хвилин пішки від будь-якої з них.",
    "paws.hours": "Ми відкриті кожної неділі, з 13:00 до 17:00, інші дні лише за домовленістю з волонтерами.",
    "paws.hours.short": "кожної неділі, 13:00-17:00",
    "paws.contactUs": "Якщо у вас є питання, будь ласка, обов'язково надішліть нам повідомлення.",
    "paws.100%":
      "100% вашої пожертви буде використано на допомогу тваринам та притулкам для тварин в регіонах України, які постраждали від війни.",
    "link.requestHelp": "Форма допомоги (перше звернення)",
    "link.requestHelpAgain": "Форма допомоги (друге та наступні звернення)",
  },
  [PawsLanguage.Swe]: {
    "paws.address": `Vi finns i Stockholm på ${pawsAddress}`,
    "paws.travelSuggestions":
      "Vi finns mellan två pendeltågsstationer: Sollentuna och Häggvik, det finns bussar tillgängliga, eller 15 minuters promenadtid från någon av stationerna.",
    "paws.hours": "Vi har öppet på söndagar, 13:00-17:00, andra dagar enligt överenskommelse med volontärerna.",
    "paws.hours.short": "Söndagar, 13:00-17:00",
    "paws.contactUs": "Om du har några frågor, tveka inte att skicka oss ett meddelande.",
    "paws.100%": "100% av din donation går till att hjälpa djur i Ukraina i regioner som drabbats mest av krig.",
    "link.requestHelp": "",
    "link.requestHelpAgain": "",
    "Page is only available in Ukrainian.": "Sidan är endast tillgänglig på ukrainska.",
    "Switch to": "Byt språk till",
    "Return to the front page": "Tillbaka till förstasidan",
  },
  [PawsLanguage.Eng]: {
    "paws.address": `We are located in Stockholm, at ${pawsAddress}`,
    "paws.address.short": pawsAddress,
    "paws.travelSuggestions":
      "The location is between two commuter train stations: Sollentuna and Häggvik, there are buses available, or 15 min walk time from either.",
    "paws.hours":
      "We are open on Sundays, from 13:00 to 17:00, other days are negotiable on a week-by-week basis and per volunteer suggestions.",
    "paws.hours.short": "Sundays, 13:00-17:00",
    "paws.contactUs": "If you have any questions, please don't hesitate to send us a message.",
    "paws.100%":
      "100% of your donation goes towards helping animals and animal shelters in war-affected zones of Ukraine.",
    "link.requestHelp": "Request Help form (first request)",
    "link.requestHelpAgain": "Request Help form (second and following requests)",
  },
};

export const PawsAddress = () => {
  const { translate } = useTranslate(strings);
  return (
    <>
      {translate("paws.address")}. {translate("paws.travelSuggestions")}
    </>
  );
};

export const PawsOpeningHours = () => {
  const { translate } = useTranslate(strings);
  return <>{translate("paws.hours")}</>;
};

export const PawsAddressShort = () => {
  const { translate } = useTranslate(strings);
  return <>{translate("paws.address.short")}</>;
};

export const PawsOpeningHoursShort = () => {
  const { translate } = useTranslate(strings);
  return <>{translate("paws.hours.short")}</>;
};

export const Paws100PercentDisclaimer = () => {
  const { translate } = useTranslate(strings);
  return (
    <>
      <h4 className="text-danger text-center p-5">{translate("paws.100%")}</h4>
    </>
  );
};

export const PawsIfYouHaveQuestions = () => {
  const { translate } = useTranslate(strings);
  return (
    <div className="mt-4 mb-4">
      ❓ <PawsLink to={PawsWebLocation.Contact}>{translate("paws.contactUs")}</PawsLink>
    </div>
  );
};

export const RequestHelpLink = () => {
  const { translate } = useTranslate(strings);
  return <PawsLink to={pawsRequestHelpFormUrl}>{translate("link.requestHelp")}</PawsLink>;
};
export const RequestHelpAgainLink = () => {
  const { translate } = useTranslate(strings);
  return <PawsLink to={pawsRequestHelpAgainFormUrl}>{translate("link.requestHelpAgain")}</PawsLink>;
};

export const PawsFacebook = () => (
  <>
    Facebook{" "}
    <PawsLink className="text-body-secondary p-1" to={pawsFacebook}>
      <img src={Facebook} className={styles.textInline} alt="Facebook" />
    </PawsLink>
  </>
);

export const PawsInstagram = () => (
  <>
    Instagram
    <PawsLink className="text-body-secondary p-1" to={pawsInstagram}>
      <img src={Instagram} className={classNames("black-and-white", styles.textInline)} alt="Instagram" />
    </PawsLink>
  </>
);

export const UkrainianOnly = () => {
  const location = useLocation();
  const { translate } = useTranslate(strings);

  return (
    <>
      <p>{translate("Page is only available in Ukrainian.")}</p>
      <p>
        <PawsLink to={`/${PawsLanguage.Ukr}${location.pathname}`}>{translate("Switch to")}: Українська</PawsLink>
      </p>
      <p>
        <PawsLink to="/">{translate("Return to the front page")}</PawsLink>
      </p>
    </>
  );
};
