import React, { ReactNode } from "react";
import styles from "./LangSwitcher.module.css";
import classNames from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";
import { PawsLanguage, DEFAULT_ENGLISH } from "../../values";
import { Col, Row } from "react-bootstrap";

export type PawsTranslateFn = (key: string) => string;

export const getBrowserLanguages = () => {
  const languages = navigator.languages || [];
  return languages.map((lang) => lang.split("-")[0]);
};

export type PawsLangStrings = {
  [lang in PawsLanguage]: { [key: string]: ReactNode };
};

export const useCurrentLanguage = () => {
  const { lang: urlLang } = useParams<{ lang: string }>();
  return Object.values(PawsLanguage).includes(urlLang as PawsLanguage) ? (urlLang as PawsLanguage) : DEFAULT_ENGLISH;
};

export const useTranslate = (strings: PawsLangStrings) => {
  const lang = useCurrentLanguage();

  const translate = (key: string): string => {
    const candidate = strings[lang][key] ?? strings[DEFAULT_ENGLISH][key] ?? key;
    return candidate.toString();
  };

  const translateJsx = (key: string): ReactNode => {
    return strings[lang][key] ?? strings[DEFAULT_ENGLISH][key] ?? key;
  };

  return { lang, translate, translateJsx };
};

export const LangSwitcher = () => {
  const location = useLocation();
  const activeLang = useCurrentLanguage();

  const ukrActive = activeLang === PawsLanguage.Ukr;
  const ukrSwitchStyle = classNames(styles.langSwitch, {
    [styles.inactive]: !ukrActive,
    [styles.active]: ukrActive,
  });

  const engActive = activeLang === PawsLanguage.Eng;
  const engSwitchStyle = classNames(styles.langSwitch, {
    [styles.inactive]: !engActive,
    [styles.active]: engActive,
  });

  const sweActive = activeLang === PawsLanguage.Swe;
  const sweSwitchStyle = classNames(styles.langSwitch, {
    [styles.inactive]: !sweActive,
    [styles.active]: sweActive,
  });

  // Create new paths by replacing the language part in the current path
  const currentPath = location.pathname;
  const basePath = currentPath.replace(/^\/[^/]+/, "");

  return (
    <Col>
      <Row>
        <Link to={`/${PawsLanguage.Ukr}${basePath}`} className={ukrSwitchStyle}>
          Українська
        </Link>
      </Row>
      <Row>
        <Link to={`/${PawsLanguage.Eng}${basePath}`} className={engSwitchStyle}>
          English
        </Link>
      </Row>
      <Row>
        <Link to={`/${PawsLanguage.Swe}${basePath}`} className={sweSwitchStyle}>
          Svenska
        </Link>
      </Row>
    </Col>
  );
};
