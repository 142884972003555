export enum PawsWebLocation {
  Root = "/",
  RootAlt = "/index.html",
  Faq = "/:lang/faq",
  RequestHelp = "/:lang/request-help",
  DonateMoney = "/:lang/donate-money",
  DonateSupplies = "/:lang/donate-supplies",
  Organisation = "/:lang/org",
  Achievements = "/:lang/reports",
  Team = "/:lang/team",
  Volunteers = "/:lang/volunteers",
  Contact = "/:lang/contact",
}

export enum PawsLanguage {
  Ukr = "uk",
  Eng = "en",
  Swe = "sv",
}

export const DEFAULT_ENGLISH = PawsLanguage.Eng;

export const pawsInfoEmail = "info@pawsofpeace.se";
export const pawsSwish = "123-016 25 29";
export const pawsBankgiro = "732-8990";
export const pawsBuymeacoffee = "https://www.buymeacoffee.com/TanyaL";
export const pawsPaypal = "https://www.paypal.com/donate/?hosted_button_id=CESU98W7BR43S";
export const pawsAddress = "Glimmervägen 10-12, Häggvik, Sollentuna";

export const swedbankOrgName = "PAWS OF PEACE";
export const orgNr = "802545-0886";
export const styrelsenValdFor: number = 2025;

export const pawsFacebook = "https://www.facebook.com/pawsofpeace";
export const pawsInstagram = "https://www.instagram.com/thepawsofpeace/";
export const pawsLinkedin = "https://www.linkedin.com/company/paws-of-peace/";
export const pawsLinktree = "https://linktr.ee/pawsofpeace";

export const pawsVolunteerFormUrl = "https://forms.gle/19He16iWwyUtJ8Dm9";
export const pawsMemberFormUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSccVyjEVI__rUhN_dw5qiI0_NiUo4v7yHCS0dgdILP3PKVYsw/viewform";
export const pawsRequestHelpFormUrl = "https://forms.gle/TepTjSrgbSpELcv66";
export const pawsRequestHelpAgainFormUrl = "https://forms.gle/TCMFJPBTPqFLbakz9";

export const stadgarSweUrl = "/stadgar/Paws of Peace.swe.pdf";
export const stadgarUkrUrl = "/stadgar/Paws of Peace.ukr.pdf";
