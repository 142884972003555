import logo from "../images/logo.png";
import { PawsLangStrings, useTranslate } from "./langSwitcher/LangSwitcher";
import { pawsAddress, PawsLanguage, pawsMemberFormUrl, pawsVolunteerFormUrl } from "../values";
import { PawsLink } from "./PawsLink";
import { Card, Col, Row } from "react-bootstrap";

function MailTo(props: { email: string }) {
  return <a href={"mailto:" + props.email}>{props.email}</a>;
}

const strings: PawsLangStrings = {
  [PawsLanguage.Ukr]: {
    Volunteers: "Волонтери",
    "Support us through becoming a member": "Підтримайте нас ставши учасником",
    "text.volunteer":
      "Волонтерська робота в Paws of Peace — це безоплатна допомога, зазвичай це пересування " +
      "та пакування коробок на адресі, де знаходиться Paws of Peace. Ми працюємо з 13 до 17 кожної неділі, " +
      "і регулюємо час роботи та дні відповідно до потреб волонтерів. Хочете приєднатися до команди волонтерів?",
    "text.volunteer.newLocation": `З 1 березня 2025 року ми працюємо за новою адресою: ${pawsAddress}`,
    "Contact us": "Заповніть форму реєстрації волонтера",
    "text.members": (
      <>
        Учасники Paws of Peace — це люди, які підтримують нашу місію та діяльність. Членський внесок: 250 кр/рік для
        дорослих або 100 кр/рік для біженців та студентів, які можуть не мати стабільного доходу. Учасники можуть брати
        участь у щорічних зборах та голосувати за членів правління на наступний рік.{" "}
        <PawsLink to={pawsMemberFormUrl}>Заповніть форму учасника (2025)</PawsLink> в формі також є QR-код для переводу
        членського внеску (на суму 100 або 250 крон).
      </>
    ),
  },
  [PawsLanguage.Swe]: {
    Volunteers: "Volontärer",
    "Support us through becoming a member": "Stöd oss genom att bli medlem",
    "text.volunteer":
      "Volontärarbete på Paws of Peace är obetalt arbete, vanligtvis innebär det att hjälpa till att flytta och packa donationer " +
      "i lådor. Vi är öppna 13 till 17 på söndagar och justerar öppettider och dagar som behövs för våra volontärer. " +
      "Vill du gå med i volontärteamet?",
    "text.volunteer.newLocation": `Från 1 mars 2025 arbetar vi vid den nya adressen: ${pawsAddress}`,
    "Contact us": "Fyll i formuläret för volontärer",
    "text.members": (
      <>
        Medlemmar i Paws of Peace är människor som stöder vår mission och verksamhet. Det finns en medlemsavgift: 250
        kr/år för vuxna eller 100 kr/år för flyktingar och studenter som kanske inte har en stabil inkomst. Medlemmar
        kan delta i årsmöten och rösta för styrelsemedlemmar.{" "}
        <PawsLink to={pawsMemberFormUrl}>Svära på enkäten (2025)</PawsLink> och hitta en QR-kod därinne för att betala
        medlemsavgift: 250 eller 100 kr.
      </>
    ),
  },
  [PawsLanguage.Eng]: {
    "text.volunteer":
      "Volunteer work is unpaid work at the Paws of Peace location, usually involves " +
      "helping moving and packing the donations into boxes. We are open 13 to 17 on Sundays and " +
      "we adjust opening hours and days as necessary for our volunteers. Want to join the volunteer team?",
    "text.volunteer.newLocation": `From 1 March 2025 we are working at the new address: ${pawsAddress}.`,
    "Contact us": "Fill in the volunteer registration form",
    "text.members": (
      <>
        Members of Paws of Peace are people who support our mission and activities. There is a membership fee: 250
        kr/year for adults or 100 kr/year for refugees and students, who might not have a stable income. Members can
        join annual meetings and vote for the board members.{" "}
        <PawsLink to={pawsMemberFormUrl}>Fill the form (2025)</PawsLink> and inside the form there is a QR-code to pay
        the membership fee: 250 or 100 kr.
      </>
    ),
  },
};

export const VolunteersPageBody = () => {
  const { translate: t, translateJsx } = useTranslate(strings);

  return (
    <>
      <h3>{t("Volunteers")}</h3>
      <p>{t("text.volunteer")}</p>
      <p>{t("text.volunteer.newLocation")}</p>

      <Row className="mt-2">
        <Col sm={6} lg={6}>
          <h3>{t("Volunteers")}</h3>
          <Card>
            <Card.Img variant="top" src={logo} alt="Logo" style={{ maxHeight: "12em", objectFit: "contain" }} />
            <Card.Body>
              <Card.Title>{t("text.volunteer")}</Card.Title>
              <PawsLink to={pawsVolunteerFormUrl} className="btn btn-primary">
                {t("Contact us")}
              </PawsLink>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} lg={6}>
          <h3>{t("Support us through becoming a member")}</h3>
          <Card>
            <Card.Img variant="top" src={logo} alt="Logo" style={{ maxHeight: "12em", objectFit: "contain" }} />
            <Card.Body>
              <Card.Title>{translateJsx("text.members")}</Card.Title>
              <PawsLink to="#" className="btn btn-primary">
                {t("Contact us")}
              </PawsLink>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
